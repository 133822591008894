import React from 'react';
import { useState } from 'react';
import './Search.css';
import { STORAGE_SERVER } from '../config.js'
import axios from "axios";
import Cookies from "js-cookie";
import { API_SERVER } from "../config.js";

const Search = ({ request_page, switch_tab}) => {

    const [searchResults, setSearchResults] = useState([]);

    const users_search = async (search_str) => {
        try {
          var session_token = Cookies.get("authToken");
          var search_string = search_str;
          const response = await axios.post(
            `https://${window.location.hostname}/api/v1/data/search_users`,
            { session_token, search_string }
          );
          if (response) {
            console.log("Search data update");
            setSearchResults(response.data);
          }
        } catch (error) {
          if (error.response) {
            console.warn(
              "Response Status:",
              error.response.status,
              "Response Data:",
              error.response.data
            );
          }
        }
      };
    

    return (
        <div className="Search">
            <h2 className="search-title">Поиск</h2>
            <div className="search-bar-container">
                <input id="search-bar-input" className="kitsunet-design-input-field" type="text" onChange={(e) => users_search(e.target.value)}/>
            </div>
            <div className="search-users-container">
                {searchResults.map(user => (
                    <div key={user.id} className="search-users-user-container" onClick={() => {
                    request_page(user.id);
                    switch_tab('other-user');
                    }}>
                    <div className="search-users-user-avatar">
                        <img className="search-users-user-avatar-src" src={`https://${window.location.hostname}/api/storage/avatars/${user.avatars?.split(";")[user.avatars?.split(";").length - 1] || 'avatar_placeholder.png' }`} alt='USER_AVATAR' />
                    </div>
                    <div className="search-users-user-text">
                        <h3 className="search-users-user-name">{user.display_name || user.username}</h3>
                        <h4 className="search-users-user-description">{user.description || 'No description available.'}</h4>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
};

export default Search;