import React, { useEffect, useRef, useCallback } from 'react';
import { useState } from 'react';
import './Music.css';
import axios from "axios";
import Cookies from "js-cookie";

import playIcon from "../assets/images/play_circle.svg"
import pauseIcon from "../assets/images/pause_circle.svg"
import likeIcon from "../assets/images/thumb_up.svg"

import addIcon from "../assets/images/attachment_add_icon.svg"

import RepeatModeIcon from "../assets/images/repeat.svg"
import RepeatOneModeIcon from "../assets/images/repeat_one.svg"

const Music = () => {

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Формат MM:SS
    };

    const [musicListData, setMusicListData] = useState(null);

    const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
    const [curTrack, setCurTrack] = useState(null);

    const [fileSelectorText, setFileSelectorText] = useState("Выбери файл")
    const [selectedMusicFile, setSelectedMusicFile] = useState(null);

    const [uploadTrackTitle, setUploadTrackTitle] = useState("");

    const [musicSearchField, setMusicSearchField] = useState("");

    const addMediaFileInputRef = useRef();
    const playerRef = useRef();

    const refreshMusicTop = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/get_music_top`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music top update");
            console.warn(data)
            setMusicListData(data)
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    };

    const music_search = async () => {
        if (musicSearchField === "") {
            refreshMusicTop();
            return;
        }
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("search", musicSearchField);
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/search_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music search update");
            console.warn(data)
            setMusicListData(data)
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    };

    const handlePlayTrack = (track_url, track_title) => {
        if (playerRef.current.src !== `https://${window.location.hostname}/api${track_url}`) {
            playerRef.current.src = `https://${window.location.hostname}/api${track_url}`
            playerRef.current.play();
        } else {
            if (playerRef.current.paused) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
        setCurrentlyPlaying(!playerRef.current.paused);
        setCurTrack(track_title);
    }

    const handleLikeTrack = async (track_id) => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("music_id", track_id)
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/like_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music liked");
            refreshMusicTop();
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handleMediaAdd = async (event) => {
        const file = event.target.files[0];
        if (file.size > 20 * 1024 * 1024) {
            alert("Файл слишком большой. Максимальный размер - 20 МБ.");
            return;
        }

        setFileSelectorText(file.name);
        const arrayBuffer = await file.arrayBuffer();

        setSelectedMusicFile(file);
    };

    const uploadMusic = async (event) => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("music_title", uploadTrackTitle)
        formData.append("music_file", selectedMusicFile)
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/upload_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music uploaded");
            refreshMusicTop();
            setSelectedMusicFile(null);
            setUploadTrackTitle("");
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handlePlayerPauseButton = () => {
        playerRef.current.paused ? playerRef.current.play() : playerRef.current.pause();
        setCurrentlyPlaying(!playerRef.current.paused);
    }

    const [loopMode, setLoopMode] = useState(0)

    const handleChangeLoopMode = () => {
        loopMode === 2 ? setLoopMode(0) : setLoopMode(loopMode + 1);
    }

    const [volume, setVolume] = useState(0.1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (playerRef.current) {
            playerRef.current.volume = newVolume;
        }
    };

    const handleTimeChange = (event) => {
        const newTime = parseFloat(event.target.value);
        setCurrentTime(newTime);
        if (playerRef.current) {
            playerRef.current.currentTime = newTime; // Перемотка плеера
        }
    };

    const handleEnd = useCallback(() => {
        console.log('Current loopMode:', loopMode);
        switch (loopMode){
            case 0:
                console.log("unhandled loop off");
                return;
            case 1:
                console.log("unhandled loop playlist");
                return;
            case 2:
                console.log("unhandled loop on");
                playerRef.current.play();
                return;
        }
      }, [loopMode]);

    useEffect(() => {
        refreshMusicTop();
        playerRef.current.volume = 0.1;


        const audio = playerRef.current;

        if (audio) {
            const updateTime = () => setCurrentTime(audio.currentTime);
            const setAudioDuration = () => setDuration(audio.duration);

            

            audio.addEventListener("timeupdate", updateTime);
            audio.addEventListener("loadedmetadata", setAudioDuration);

            audio.addEventListener("ended", handleEnd)

            return () => {
                audio.removeEventListener("timeupdate", updateTime);
                audio.removeEventListener("loadedmetadata", setAudioDuration);
                audio.removeEventListener("ended", handleEnd)
            };
        }
    }, [handleEnd]);

    return (
        <div className="Music">
            <audio ref={playerRef} src='' hidden>
                Your browser does not support the audio element.
            </audio>

            <div className="music-main-panel">
                <div className="music-player-holder">
                    <button className="music-player-play-button" onClick={handlePlayerPauseButton} disabled={!curTrack}>
                        <img src={currentlyPlaying ? pauseIcon : playIcon} />
                    </button>
                    <div className="music-player-range-title-holder">
                        <h4>{curTrack ? curTrack : 'Выберите музыку'}</h4>
                        <div className="slidecontainer">
                            <div className="duration-slider-holder">
                                {formatTime(currentTime)}
                                <input
                                    type="range"
                                    min="0"
                                    max={duration || 0}
                                    className="slider"
                                    step="0.1"
                                    value={currentTime}
                                    onChange={handleTimeChange}
                                    style={duration ? {
                                        background: `linear-gradient(to right, #e5b782 ${(currentTime / duration) * 100
                                            }%, #27233d ${(currentTime / duration) * 100}%)`,
                                    } : {
                                        background: `linear-gradient(to right, #e5b782 ${0}%, #27233d ${0}%)`,
                                    }}
                                />
                                {formatTime(duration)}
                                <button className='music-player-repeat-mode' onClick={handleChangeLoopMode}>
                                    <img className={loopMode === 0 ? 'loop-off' : 'loop-on'} src={loopMode === 2 ? RepeatOneModeIcon : RepeatModeIcon} />
                                </button>
                            </div>

                            <div className="volume-slider-holder">
                                <h4>Громкость: </h4>
                                <input
                                    className="slider"
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={volume}
                                    onChange={handleVolumeChange}
                                    style={{
                                        background: `linear-gradient(to right, #e5b782 ${volume * 100
                                            }%, #27233d ${volume * 100}%)`,
                                    }}
                                />
                                {Math.round(volume * 100)}%
                            </div>
                        </div>
                    </div>
                </div>

                <div className="music-list-holder">
                    <h2 className="Music-title">Топ музыки</h2>
                    <div className="music-search-bar-container">
                        <input id="music-search-bar-input" className="kitsunet-design-input-field" type="text" placeholder='' value={musicSearchField} onChange={(e) => { setMusicSearchField(e.target.value); music_search(); }} />
                    </div>
                    <div className="music-search-music-container">
                        {musicListData && musicListData.tracks.map((track) => (
                            <div className="music-item-holder">
                                <div className="music-item-left-holder">
                                    <button className="music-item-play" onClick={() => { handlePlayTrack(track.storage_url, track.title) }}>
                                        <img src={(playerRef.current.src === `https://${window.location.hostname}/api${track.storage_url}` & currentlyPlaying) ? pauseIcon : playIcon} alt="" />
                                    </button>
                                    <h3>{track.title}</h3>
                                </div>
                                <div className="music-item-right-holder">
                                    <h4>Рейтинг: {track.score}</h4>
                                    <button className="music-item-play" onClick={() => { handleLikeTrack(track.id) }}>
                                        <img src={likeIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="music-upload-container">
                    <input className='kitsunet-design-input-field' type="text" value={uploadTrackTitle} onChange={(e) => setUploadTrackTitle(e.target.value)} />
                    <input
                        ref={addMediaFileInputRef}
                        type="file"
                        onChange={handleMediaAdd}
                        multiple
                        hidden
                    />
                    <div className="music-upload-buttons-holder">
                        <button
                            className="music-upload-add-attachment"
                            onClick={() => addMediaFileInputRef.current.click()}
                        >
                            <img src={addIcon} alt="" />
                        </button>
                        <h3>{fileSelectorText}</h3>
                        <button
                            className="kitsunet-design-input-button"
                            onClick={uploadMusic}
                        >
                            Загрузить
                        </button>
                    </div>

                </div>
            </div>

            <div className="music-additional-panel">

            </div>


        </div>
    );
};

export default Music;