import "./Settings.css";
import React, { useEffect, useState, useRef } from "react";

import { API_SERVER, STORAGE_SERVER } from "../config.js";

import axios from "axios";
import Cookies from "js-cookie";

import { Image } from "image-js";

import PlaceholderIcon from "../assets/images/fox.png";

import { useNotifications } from "../context/NotificationContext.js";
import CrazyFoxIcon from "../assets/images/Untitled-1.png"

import CloseIcon from "../assets/images/check.svg"

import sendIcon from "../assets/images/send.svg"

import MyPackIcon from "../assets/images/emoji_people.svg"

const Settings = ({ my_user_data, refresh_user_data, add_avatar }) => {

  const addStickerInputRef = useRef(null);
  const { sendNotification } = useNotifications();

  const avatarHiddenInputRef = useRef();

  const [activeSettingsTab, setActiveSettingsTab] = useState("profile");

  const [fieldUsername, setFieldUsername] = useState(
    my_user_data.username || ""
  );

  const [fieldDisplayname, setFieldDisplayname] = useState(
    my_user_data.display_name || ""
  );
  const [fieldDescription, setFieldDescription] = useState(
    my_user_data.description || ""
  );
  const [fieldLocation, setFieldLocation] = useState(
    my_user_data.location || ""
  );

  const [sessionsData, setSessionsData] = useState([]);

  const [userAvatars, setUserAvatars] = useState(() => {
    if (my_user_data.avatars) {
      return my_user_data.avatars.split(";");
    } else {
      return [];
    }
  });

  const [createStickerpackOpened, setCreateStickerpackOpened] = useState(false);

  const [stickerCreateModalOpened, setStickerCreationModalOpened] = useState(false);

  const switchSettingsTab = (tabId) => {
    setActiveSettingsTab(tabId);
  };

  const handleSaveProfile = async () => {
    const formData = new FormData();
    formData.append("display_name", fieldDisplayname);
    formData.append("description", fieldDescription);
    formData.append("avatars", userAvatars.join(";"));
    formData.append("location", fieldLocation);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/change_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success change profile");
        refresh_user_data();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleChangeUsername = async () => {
    const formData = new FormData();
    formData.append("username", fieldUsername);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/change_username`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success change username");
        refresh_user_data();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const moveToEnd = (array, element) => {
    const index = element;
    if (index !== -1) {
      const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
      newArray.unshift(array[element]);
      return newArray;
    }
    return array;
  };

  const popAvatars = (array, item) => {
    if (item !== -1 && item < array.length) {
      const newArray = array.filter((_, index) => index !== item);
      return newArray;
    }
    return array;
  };

  const handleMoveToEnd = (item) => {
    const updatedArray = moveToEnd([...userAvatars].reverse(), item);
    setUserAvatars(updatedArray.reverse());
  };

  const handleDeleteAvatar = (item) => {
    const updatedArray = popAvatars([...userAvatars].reverse(), item);
    setUserAvatars(updatedArray.reverse());
  };

  const handleAllSessionClosure = async () => { };

  const handleSessionClosure = async (session_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("target_session", session_id);
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/auth/close_session`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success closure");
        refreshSessions();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );

        sendNotification("Ошибка", error.response.data.detail);
      }
    }
  };

  const [stickersLibraryData, setStickersLibraryData] = useState([]);

  const get_stickers_library = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/get_my_stickerpacks_library`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Search stickers update");
      setStickersLibraryData(data)
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  const [stickerSearchData, setStickerSearchData] = useState([]);

  const stickers_search = async (search_str) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("stickerpack_search", search_str)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/search_stickerpacks`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Search stickers update");
      setStickerSearchData(data)
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  const handleLogout = () => {
    Cookies.remove("authToken");
    window.location.reload();
  };

  const refreshSessions = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/auth/get_sessions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Sessions update");
        setSessionsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const add_stickerpack_to_library = async (stickerpack_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("stickerpack_id", stickerpack_id)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/stickerpack_to_library`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Added stickers update");
      get_stickers_library();
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  const remove_stickerpack_from_library = async (stickerpack_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("stickerpack_id", stickerpack_id)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/remove_stickerpack_from_library`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Remove stickers update");
      get_stickers_library();
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  const [curOpenedStickerPack, setCurOpenedStickerPack] = useState(null);


  const [isActive, setIsActive] = useState(false);
  const timerRef = useRef(null);

  const handleMouseDown = () => {
    timerRef.current = setTimeout(() => {
      setIsActive(true);
    }, 2000);
  };

  const handleMouseUp = () => {
    clearTimeout(timerRef.current);
    setIsActive(false);
  };

  const [creationStickerPackTitle, setCreationStickerPackTitle] = useState("");
  const [importStickerPackName, setImportStickerPackName] = useState("");
  const [importStickerPackNameNewTitle, setImportStickerPackNameNewTitle] = useState("");

  const remove_sticker_from_pack = async (sticker_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("sticker_id", sticker_id)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/remove_sticker_from_pack`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Remove sticker successfully");
      get_stickers_library();
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  const handleAddSticker = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      let arrayBuffer = await file.arrayBuffer();
      let image = await Image.load(arrayBuffer);

      let originalWidth = image.width;
      let originalHeight = image.height;

      let targetSize = Math.min(originalWidth, originalHeight);

      let croppedImage = image.crop({
        x: (originalWidth - targetSize) / 2,
        y: (originalHeight - targetSize) / 2,
        width: targetSize,
        height: targetSize,
      });
      let resized_image = croppedImage.resize({ width: 256, height: 256 });
      const resizedImageBuffer = resized_image.toBuffer();
      const blob = new Blob([resizedImageBuffer], { type: "image/png" });

      const pack_id = stickersLibraryData.find(pack => pack.title === curOpenedStickerPack).id;
      const formData = new FormData();
      formData.append("session", Cookies.get("authToken"));
      formData.append("sticker_file", blob, "sticker.png");
      formData.append("stickerpack_id", pack_id)

      await axios.post(
        `https://${window.location.hostname}/api/v1/client/add_sticker_to_pack`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data", },
        }
      ).then(({ data }) => {
        console.log("Created sticker");
        get_stickers_library();
      }).catch(error => {
        const { status, data } = error.response;
        console.error("Response Status:", status, "Response Data:", data);
      })
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCreateStickerPack = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("stickerpack_name", creationStickerPackTitle)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/create_stickerpack`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Created stickers");
      get_stickers_library();
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  };

  const handleImportStickers = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("tg_stickers_name", importStickerPackName)
    formData.append("stickerpack_title", importStickerPackNameNewTitle)
    await axios.post(
      `https://${window.location.hostname}/api/v1/client/tg_to_kitsunet_stickers`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data", },
      }
    ).then(({ data }) => {
      console.log("Import success");
      get_stickers_library();
    }).catch(error => {
      const { status, data } = error.response;
      console.error("Response Status:", status, "Response Data:", data);
    })
  }

  useEffect(() => {
    if (my_user_data.avatars) {
      setUserAvatars(my_user_data.avatars.split(";"));
    } else {
      setUserAvatars([]);
    }
  }, [my_user_data]);

  useEffect(() => {
    refreshSessions();
    get_stickers_library();
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div className="Settings settings-navigation-container">
      <div className="create-new-stickerpack-dialog" style={createStickerpackOpened ? { display: "block" } : { display: "none" }}>
        <div className="create-new-stickerpack-dialog-content">
          <div className="modal-join-guild">
            <h2>Импорт</h2>
            <p>Вы можете перенести стикерпак из телеграм сюда и использовать его как и раньше</p>
            <div className="modal-hor-hold">
              <input className="kitsunet-design-input-field" type="text" placeholder='Назвние стикерпака из телеграм' value={importStickerPackName} onChange={(e) => setImportStickerPackName(e.target.value)} required />
              <input className="kitsunet-design-input-field" type="text" placeholder='Новое название для переноса в Kitsunet' value={importStickerPackNameNewTitle} onChange={(e) => setImportStickerPackNameNewTitle(e.target.value)} required />
              <button className='guild-chat-send-button' type="submit" onClick={handleImportStickers}>
                <img className="send-icon" src={sendIcon} alt="send"></img>
              </button>
            </div>
          </div>
          <div className="modal-create-guild">
            <h2>Создать</h2>
            <p>Или создайте свой собственный набор стикеров</p>
            <div className="modal-hor-hold">
              <input className="kitsunet-design-input-field" type="text" placeholder='Название нового стикерпака' value={creationStickerPackTitle} onChange={(e) => setCreationStickerPackTitle(e.target.value)} required />
              <button className='guild-chat-send-button' type="submit" onClick={handleCreateStickerPack}>
                <img className="send-icon" src={sendIcon} alt="send"></img>
              </button>
            </div>

          </div>
          <div className='close modal-close' onClick={() => setCreateStickerpackOpened(false)}>&times;</div>
        </div>
      </div>
      <div className="settings-navigation-bar">
        <span className="settings-nav-label">Настройки пользователя</span>
        <button
          className="settings-nav-button"
          onClick={() => switchSettingsTab("profile")}
        >
          Профиль
        </button>
        <span className="settings-nav-seperator"></span>
        <span className="settings-nav-label">Мессенджер</span>
        <button
          className="settings-nav-button"
          onClick={() => switchSettingsTab("stickers")}
        >
          Стикеры
        </button>
        <span className="settings-nav-seperator"></span>
        <span className="settings-nav-label">Безопасность</span>
        <button
          className="settings-nav-button"
          onClick={() => switchSettingsTab("sessions")}
        >
          Сессии
        </button>
        <span className="settings-nav-seperator"></span>
        <span className="settings-nav-label">Другое</span>
        {/* <button className="settings-nav-button" onClick={() => switchSettingsTab('phonk')}>Фонк</button> */}
        <button className="settings-nav-button" onClick={() => handleLogout()}>
          Выйти из учетной записи
        </button>
      </div>

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "profile"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="settings-user-container">
          <h2 className="settings-user-title">Пользователь</h2>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Имя пользователя</h4>
            <div className="settings-user-setting-field-and-button">
              <input
                className="kitsunet-design-input-field settings-user-25w"
                type="text"
                placeholder="..."
                value={fieldUsername}
                onChange={(e) => setFieldUsername(e.target.value)}
                required
              />
              <button
                className="kitsunet-design-input-button "
                onClick={handleChangeUsername}
              >
                Сохранить
              </button>
            </div>
          </div>
          <div className="settings-user-setting-seperator"></div>
          <h2 className="settings-user-title">Профиль</h2>
          <button
            className="kitsunet-design-input-button "
            onClick={handleSaveProfile}
          >
            Сохранить изменения профиля
          </button>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Отображаемое имя</h4>
            <input
              className="kitsunet-design-input-field settings-user-25w"
              type="text"
              placeholder="Имя или ник"
              value={fieldDisplayname}
              onChange={(e) => setFieldDisplayname(e.target.value)}
              required
            />
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Описание профиля</h4>
            <input
              className="kitsunet-design-input-field"
              type="text"
              placeholder="Расскажите что-нибудь о себе"
              value={fieldDescription}
              onChange={(e) => setFieldDescription(e.target.value)}
              required
            />
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Аватары</h4>
            <div
              className="setting-user-avatars-avatars-container"
              key="avatars-container"
            >
              <div
                className="settings-user-avatars-none-container"
                onClick={() => avatarHiddenInputRef.current.click()}
              >
                <input
                  ref={avatarHiddenInputRef}
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={add_avatar}
                  style={{ display: "none" }}
                />
                <div className="setting-user-avatars-avatars-avatar-add-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    viewBox="0 -960 960 960"
                    width="48"
                  >
                    <path
                      fill="#FFFFFF"
                      d="M440-440H240q-17 0-28.5-11.5T200-480q0-17 11.5-28.5T240-520h200v-200q0-17 11.5-28.5T480-760q17 0 28.5 11.5T520-720v200h200q17 0 28.5 11.5T760-480q0 17-11.5 28.5T720-440H520v200q0 17-11.5 28.5T480-200q-17 0-28.5-11.5T440-240v-200Z"
                    />
                  </svg>
                </div>
              </div>
              {[...userAvatars].reverse().map((avatar, index) => (
                <div key={avatar} className="setting-user-avatars-avatars-avatar-container">
                  <img
                    className="setting-user-avatars-avatars-avatar-src"
                    src={`https://${window.location.hostname}/api/storage/avatars/${avatar}`}
                    alt=""
                    onClick={() => handleMoveToEnd(index)}
                  />
                  <div
                    className="settings-user-avatars-cross"
                    onClick={() => handleDeleteAvatar(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Местоположение</h4>
            {/* <h4 className="settings-user-location-location-label"> Cанкт-Петербург, Россия
                            <svg className="settings-user-location-location-label-edit" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  d="M167-120q-21 5-36.5-10.5T120-167l40-191 198 198-191 40Zm191-40L160-358l458-458q23-23 57-23t57 23l84 84q23 23 23 57t-23 57L358-160Zm317-600L261-346l85 85 414-414-85-85Z"/></svg>
                        </h4> */}
            <input
              className="kitsunet-design-input-field settings-user-25w"
              type="text"
              placeholder="Город или страна"
              value={fieldLocation}
              onChange={(e) => setFieldLocation(e.target.value)}
              required
            />
          </div>
        </div>
      </div>

      {activeSettingsTab === "stickers" && (
        <div
          className="settings-tab-content"
        >
          <h2>Управление стикерами</h2>
          <div className="sticker-editor-hor-div">
            <div className="sticker-editor-search">
              <input placeholder="Поиск стикерпаков" id="search-bar-input" className="kitsunet-design-input-field" type="text" onChange={(e) => stickers_search(e.target.value)} />
              {stickerSearchData.map((stickerpack) => (
                <div className="stickerpack-search-item">
                  <h3>{stickerpack.title}</h3>
                  {!stickerpack.inLibrary && (
                    <div className="stickerpack-search-add-button" onClick={() => add_stickerpack_to_library(stickerpack.id)}>
                      +
                    </div>)}

                </div>
              ))}
              <img src={CrazyFoxIcon} alt="Your image" className="attached-image"></img>
            </div>
            <div className="sticker-editor-main-holder">
              <div className="sticker-editor-main-top-scroller">
                <div className="sticker-editor-main-top-scroller-item item-t3kd-back" onClick={() => setCreateStickerpackOpened(true)}>
                  +
                </div>
                {stickersLibraryData.map((stickerpack) => (
                  <div className="sticker-editor-main-top-scroller-item" >
                    {stickerpack.stickers[0] ? (
                      <img src={`https://${window.location.hostname}/api${stickerpack.stickers[0].storage_url}`} alt="" onClick={() => setCurOpenedStickerPack(stickerpack.title)} />
                    ) : (
                      <h3 onClick={() => setCurOpenedStickerPack(stickerpack.title)}>
                        {Array.from(stickerpack.title)[0]}
                      </h3>
                    )}

                    <div className="sticker-editor-main-top-remove-stickerpack-from-lib" onClick={() => remove_stickerpack_from_library(stickerpack.id)}>
                      ×
                    </div>
                  </div>
                ))}
              </div>
              <div className="sticker-editor-bottom-list">
                {curOpenedStickerPack && stickersLibraryData && stickersLibraryData.find(pack => pack.title === curOpenedStickerPack) && stickersLibraryData.find(pack => pack.title === curOpenedStickerPack).stickers.map((sticker) => (
                  <div className='sticker-editor-bottom-list-item'>
                    <img src={`https://${window.location.hostname}/api${sticker.storage_url}`} alt="" />
                    {stickersLibraryData.find(pack => pack.title === curOpenedStickerPack).owned && (
                      <div className="sticker-editor-main-bottom-remove-sticker-from-pack" onClick={() => remove_sticker_from_pack(sticker.id)}>
                        ×
                      </div>)}
                  </div>
                ))}

                {curOpenedStickerPack && stickersLibraryData && stickersLibraryData.find(pack => pack.title === curOpenedStickerPack) && stickersLibraryData.find(pack => pack.title === curOpenedStickerPack).owned && (
                  <div className='sticker-editor-bottom-list-item' onClick={() => addStickerInputRef.current.click()}>
                    <input
                      ref={addStickerInputRef}
                      type="file"
                      accept=".jpeg,.jpg,.png"
                      onChange={handleAddSticker}
                      style={{ display: "none" }}
                    />
                    <div className="add-sticker-button">
                      +
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "sessions"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="sessions-container">
          <h3>Ваши активные сессии:</h3>
          {sessionsData
            .filter((session) => session.current)
            .map((session) => (
              <div key={session.id} className="session-container">
                <div>
                  <h3>Текущая сессия</h3>
                  <h4>IP: {session.ip}</h4>
                  <h4>Последняя активность: {session.last_seen}</h4>
                  <h5>id{session.id}</h5>
                </div>
              </div>
            ))}
          {/* <div>
            <button
              className="kitsunet-design-input-button "
              onClick={() => handleAllSessionClosure()}
            >
              Закрыть все остальное сессии
            </button>
          </div> */}
          {sessionsData
            .filter((session) => !session.current)
            .map((session) => (
              <div key={session.id} className="session-container">
                <div>
                  <h4>IP: {session.ip}</h4>
                  <h4>Последняя активность: {session.last_seen}</h4>
                  <h5>id{session.id}</h5>
                </div>
                <div>
                  <button
                    className="kitsunet-design-input-button "
                    onClick={() => handleSessionClosure(session.id)}
                  >
                    Закрыть сессию
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "logout"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        Выход из аккаунта 𓃥
      </div>
    </div>
  );
};

export default Settings;
