import React, { Children, useCallback, useEffect, useState } from "react";
import "./Placeholder.css";
import LoadingIcon from "../assets/images/refresh.svg";
import DoneIcon from "../assets/images/check.svg";
import ErrorIcon from "../assets/images/error.svg"

import { API_SERVER } from "../config.js";
import axios from "axios";
import Cookies from "js-cookie";


import { useSocket } from "../context/SocketContext.js";

const Placeholder = ({ children }) => {
  const { readyState } = useSocket();

  const [currentPage, setCurrentPage] = useState(true)

  const [rotateTimer, setRotateTimer] = useState(null);
  const [animationProgress, setAnimationProgress] = useState(0);
  const [loadingStages, setLoadingStages] = useState([
    {
      name: "socket",
      display_name: "Соединение с сервером",
      loading_icon: LoadingIcon,
      done_icon: DoneIcon,
      done: 0,
      rotation: 0,
    },
    {
      name: "auth",
      display_name: "Аудентификация",
      loading_icon: LoadingIcon,
      done_icon: DoneIcon,
      done: 0,
      rotation: 0,
    },
  ]);

  const animateToCenter = () => {
    setAnimationProgress(1);
  };

  const animateToTop = () => {
    setAnimationProgress(0);
  };

  const auth_check = async () => {
    var session_token = Cookies.get("authToken");
    axios.post(`https://${window.location.hostname}/api/v1/client/online_ping`, {
        session_token
      })
      .then(function (response) {
        setStageDone('auth', 1)
      })
      .catch(function (error) {
        setStageDone('auth', 2)
      });
  }

  const setStageDone = (stage_name, value_to_set) => {
    setLoadingStages((prevStages) => {
      const newStages = [...prevStages];
      const target_stage = newStages.find((stage) => stage.name === stage_name);
      if (target_stage === undefined) return;

      target_stage.done = value_to_set;
      target_stage.rotation = 0;
      return newStages;
    });
  };

  const setLoadingStage = useCallback(
    (index) => {
      const newStages = [...loadingStages];
      const target_deg = (newStages[index].rotation + 5) % 360;
      newStages[index].rotation = target_deg;
      return newStages;
    },
    [loadingStages]
  );

  useEffect(() => {
    if (readyState === 1) {
        setStageDone("socket", 1)
    }
  }, [readyState])

  useEffect(() => {
      const allDone = loadingStages.every(stage => stage.done === 1);
      if (allDone) {
        animateToCenter();
        setCurrentPage(false);
      }
  }, [loadingStages]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadingStages.forEach((stage, index) => {
        if (stage.done === 0) {
          setLoadingStages(setLoadingStage(index));
        }
      });
    }, 15);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    animateToTop();
    auth_check();
  }, [])

  
  return (
    <>
    {currentPage ? (
        <div className={`App-loading ${animationProgress > 0 ? 'animate-center' : ''}`}>
        <div className="loading-main-holder">
          {loadingStages.map((stage, index) => (
            <div key={stage.name} className="loading-main-item">
              <img
                className="loading-svg"
                src={stage.done === 0 ? LoadingIcon : stage.done === 1 ? DoneIcon : ErrorIcon}
                alt="loading"
                style={{ transform: `rotate(${stage.rotation}deg)` }}
              />
              <h2>{stage.display_name}</h2>
            </div>
          ))}
        </div>
      </div>
    ) : (
        <>{children}</>
    )}
    </>
    
  );
};

export default Placeholder;
