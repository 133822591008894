import React from "react";
import { useState, useEffect, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import "./Game.css";

const Game = () => {
  const gameUrl = "https://kitsunet.ru/api/storage/hills/";
  const [unityGameToggled, setUnityGameToggled] = useState(false);
  const { unityProvider } = useUnityContext({
    loaderUrl: gameUrl+"Build/Builds.loader.js",
    dataUrl: gameUrl+"Build/Builds.data",
    frameworkUrl: gameUrl+"Build/Builds.framework.js",
    codeUrl: gameUrl+"Build/Builds.wasm",
  });

  const handleToggleGame = () => {
    setUnityGameToggled(!unityGameToggled);
  };

  return (
    <div className="Game">
      <button
        className="kitsunet-design-input-button"
        onClick={handleToggleGame}
      >
        ToggleGame
      </button>
      {unityGameToggled && (
        <Unity className="unity-container" unityProvider={unityProvider} />
      )}
      
    </div>
  );
};

export default Game;
