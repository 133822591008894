import React from 'react';

import { STORAGE_SERVER } from '../config.js'
import axios from "axios";
import Cookies from "js-cookie";
import { API_SERVER } from "../config.js";

import "./Friends.css";

const Friends = ({refresh_friends, friendsData, friendsRequestsData, request_page, switch_tab }) => {

    const handleAcceptFriendRequest = async (request_id) => {
      var session_token = Cookies.get("authToken");
      var target_request_id = request_id;
      try {
        const response = await axios.post(
          `https://${window.location.hostname}/api/v1/client/accept_friend_request`,
          { session_token, target_request_id }
        );
        if (response) {
          console.log("Success send friend request");
          refresh_friends();
          // setOtherUserPosts(response.data)
        }
      } catch (error) {
        if (error.response) {
          console.error(
            "Response Status:",
            error.response.status,
            "Response Data:",
            error.response.data
          );
        }
      }
    };

    return (
        <div className="Friends">
             <div className="friends-container">
            <h1>Друзья</h1>
            <div className="friends-main-content-container">
              <div className="friends-profile-container">
                {/* <div className="friends-search-container">
                  <input className="kitsunet-design-input-field" type="text" placeholder="Поиск друзей"/>
                </div> */}
                {friendsData.friends && (friendsData.friends.map(user => (
                <div key={user.user_id} className="friends-search-users-user-container" onClick={() => {
                    request_page(user.user_id);
                    switch_tab('other-user');
                }}>
                  <div className="search-users-user-avatar">
                    <img className="search-users-user-avatar-src" src={`https://${window.location.hostname}/api/storage/avatars/${user.avatars?.split(";")[user.avatars?.split(";").length - 1] || 'avatar_placeholder.png' }`} alt='USER_AVATAR' />
                  </div>
                  <div className="search-users-user-text">
                    <h3 className="search-users-user-name">{user.display_name || user.username || 'Имя скрыто'}</h3>
                    <h4 className="search-users-user-description">{user.description || 'No description available.'}</h4>
                </div>
              </div>
              )))}
              </div>
              <div className="friends-all-incoming-outgouig-container">
                
                <h3>Входящие запросы</h3>
                {
                  friendsRequestsData.received_requests && (
                    friendsRequestsData.received_requests.length === 0 ? (
                      <h2>
                        Пусто
                      </h2>
                    ) : (
                      friendsRequestsData.received_requests.map(request => (
                      <div className="profile-page-additional-panel-friend-container" key={request.id}
                        onClick={() => {
                          request_page(request.user_id);
                          switch_tab('other-user');
                        }}>
                        <div className="profile-page-additional-panel-friend-avatar">
                          <img className="profile-page-additional-panel-friend-avatar-src" src={`https://${window.location.hostname}/api/storage/avatars/${request.avatars?.split(";")[request.avatars?.split(";").length - 1] || 'avatar_placeholder.png' }`} alt='USER_AVATAR' />
                        </div>
                        <div className="profile-page-additional-panel-friend-text-container">
                          <div className="profile-page-additional-panel-friend-text-username">
                            {request.display_name || request.username || 'Имя скрыто' }
                          </div>
                          <div className="profile-page-additional-panel-friend-text-status">
                            {request.description || 'No description available.'}
                            <button className="kitsunet-design-input-button" onClick={() => handleAcceptFriendRequest(request.id)}>Принять</button>
                          </div>
                        </div>
                      </div>
                    )))
                  )
                }
                
                
                <h3>Исходящие запросы</h3>
                {
                  friendsRequestsData.sent_requests && (
                    friendsRequestsData.sent_requests.length === 0 ? (
                      <h2>
                        Пусто
                      </h2>
                    ) : (
                      friendsRequestsData.sent_requests.map(request => (
                      <div className="profile-page-additional-panel-friend-container" key={request.id} onClick={() => {
                          request_page(request.user_id);
                          switch_tab('other-user');
                        }}>
                        <div className="profile-page-additional-panel-friend-avatar">
                          <img className="profile-page-additional-panel-friend-avatar-src" src={`https://${window.location.hostname}/api/storage/avatars/${request.avatars?.split(";")[request.avatars?.split(";").length - 1] || 'avatar_placeholder.png' }`} alt='USER_AVATAR' />
                        </div>
                        <div className="profile-page-additional-panel-friend-text-container">
                          <div className="profile-page-additional-panel-friend-text-username">
                            {request.display_name || request.username}
                          </div>
                          <div className="profile-page-additional-panel-friend-text-status">
                            {request.description || 'No description available.'}
                          </div>
                        </div>
                      </div>
                    )))
                  )
                }
              </div>
            </div>
            
          </div>
        </div>
    );
};

export default Friends;